import Analytics from "@telenornorgeinternal/frontend-analytics";

export default defineNuxtPlugin((nuxtApp) => {
	const { $config } = nuxtApp;
	onNuxtReady(() => {
		if ($config.public.faroUrl && $config.public.faroApiKey && $config.public.appName) {
			Analytics.TnAnalytics.create({
				faroConfig: {
					url: $config.public.faroUrl,
					apiKey: $config.public.faroApiKey,
					app: {
						name: $config.public.appName,
					},
				},
				autoTrackClicks: true,
				autoTrackNavigation: true,
				requiredConsent: [Analytics.ConsentOptions.ANALYTICS],
			});
		}
	});
});
