import tvContent from "~/integrations/tv-content";
import { _fetchData } from "~/helpers/store";
const getDefaultState = () => {
	return {
		promise: null,
		data: [],
		error: null,
		toAddUnsavedChannelIds: [],
		toRemoveUnsavedChannelIds: [],
		isAddDefaultSetup: false,
	};
};

export const state = () => getDefaultState();
export const actions = {
	async fetchData(context, { subscriptionId, force, isTweBoxWebApp = false }) {
		if (subscriptionId && !isTweBoxWebApp) {
			return _fetchData(() => tvContent.getSubscriptionTvContent(subscriptionId), {
				context,
				force,
				name: "tvContentSubscription",
			});
		} else if (isTweBoxWebApp) {
			return _fetchData(() => tvContent.getSubscriptionTvContentStb(), {
				context,
				force,
				name: "tvContentSubscription",
			});
		}
	},
	async validateSelectedContent(context, { subscriptionId, channelIds }) {
		if (subscriptionId) {
			return tvContent.validateSelectedContent(subscriptionId, channelIds);
		} else {
			return tvContent.validateSelectedContentStb(channelIds);
		}
	},
	async updateSelectedContent(context, { subscriptionId, channelIds, smsCode }) {
		if (subscriptionId) {
			return tvContent.updateSelectedContent(subscriptionId, channelIds);
		} else {
			return tvContent.updateSelectedContentStb(channelIds, smsCode);
		}
	},
	async resetToDefaultSetup(context, { subscriptionId }) {
		return tvContent.resetToDefaultSetup(subscriptionId);
	},
	async validateDefaultSetup(context, { subscriptionId }) {
		return tvContent.validateDefaultSetup(subscriptionId);
	},
	async getNotifications(context, { subscriptionId }) {
		return tvContent.getNotifications(subscriptionId);
	},
	async getSubscriptionRentedMovies(context, { subscriptionId }) {
		return tvContent.getSubscriptionRentedMovies(subscriptionId);
	},
	addRemoveChannel(context, { channelId }) {
		const isChannelInSubscription = () => {
			let exists = false;
			context.getters.subscriptionTvContent.subscribedContent?.forEach((id) => {
				if (id === channelId) {
					exists = true;
				}
			});
			return exists;
		};
		const isChannelAdded = () => {
			if (context.getters.toRemoveUnsavedChannelIds?.includes(channelId)) {
				return false;
			}
			if (context.getters.toAddUnsavedChannelIds?.includes(channelId) || isChannelInSubscription()) {
				return true;
			}
			return false;
		};
		if (isChannelAdded() && context.getters.toAddUnsavedChannelIds.includes(channelId)) {
			context.commit("toAddUnsavedChannelIdsRemove", channelId);
			return;
		}
		if (isChannelAdded() && !context.getters.toAddUnsavedChannelIds.includes(channelId)) {
			context.commit("toRemoveUnsavedChannelIdsPush", channelId);
			return;
		}
		if (!isChannelAdded() && context.getters.toRemoveUnsavedChannelIds.includes(channelId)) {
			context.commit("toRemoveUnsavedChannelIdsRemove", channelId);
			return;
		}
		if (!isChannelAdded() && !context.getters.toRemoveUnsavedChannelIds.includes(channelId)) {
			context.commit("toAddUnsavedChannelIdsPush", channelId);
		}
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	setIsAddDefaultSetup(state, value) {
		state.isAddDefaultSetup = value;
	},
	resetState(state, includeServerData) {
		if (includeServerData) {
			Object.assign(state, getDefaultState());
		} else {
			state.toAddUnsavedChannelIds = [];
			state.toRemoveUnsavedChannelIds = [];
			state.subscriptionId = "";
			state.isAddDefaultSetup = false;
		}
	},
	resetToAddAndRemoveUnsavedChannelIds(state) {
		state.toAddUnsavedChannelIds = [];
		state.toRemoveUnsavedChannelIds = [];
	},
	toAddUnsavedChannelIdsPush(state, channelId) {
		state.toAddUnsavedChannelIds.push(channelId);
	},
	toAddUnsavedChannelIdsRemove(state, channelId) {
		const indexToRemove = state.toAddUnsavedChannelIds.indexOf(channelId);
		if (indexToRemove > -1) {
			state.toAddUnsavedChannelIds.splice(indexToRemove, 1);
		}
	},
	toRemoveUnsavedChannelIdsPush(state, channelId) {
		state.toRemoveUnsavedChannelIds.push(channelId);
	},
	toRemoveUnsavedChannelIdsRemove(state, channelId) {
		const indexToRemove = state.toRemoveUnsavedChannelIds.indexOf(channelId);
		if (indexToRemove > -1) {
			state.toRemoveUnsavedChannelIds.splice(indexToRemove, 1);
		}
	},
};
export const getters = {
	loading: (state) => {
		return state.promise !== null;
	},
	error: (state) => {
		return state.error;
	},
	subscriptionTvContent: (state) => {
		return state.data;
	},
	toAddUnsavedChannelIds: (state) => {
		return state.toAddUnsavedChannelIds;
	},
	toRemoveUnsavedChannelIds: (state) => {
		return state.toRemoveUnsavedChannelIds;
	},
	isAddDefaultSetup: (state) => {
		return state.isAddDefaultSetup;
	},
	channelIdsSelectedByUser: (state, getters) => {
		const subscribedContent = [...getters.subscriptionTvContent.subscribedContent];
		getters.toAddUnsavedChannelIds.forEach((channelId) => {
			subscribedContent.push(channelId);
		});
		getters.toRemoveUnsavedChannelIds.forEach((channelId) => {
			const index = subscribedContent.indexOf(channelId);
			if (index > -1) {
				subscribedContent.splice(index, 1);
			}
		});
		return subscribedContent;
	},
	unsavedUsedPoints: (state, getters, rootState, rootGetters) => {
		const toAddUnsavedChannels = rootGetters["mittTelenor/fixed/tvContent/getChannelsByIds"](
			getters.toAddUnsavedChannelIds,
		);
		let totalPointsFromToAddUnsavedChannels = 0;
		toAddUnsavedChannels.forEach((channel) => {
			totalPointsFromToAddUnsavedChannels += channel.points;
		});

		const toRemoveUnsavedChannels = rootGetters["mittTelenor/fixed/tvContent/getChannelsByIds"](
			getters.toRemoveUnsavedChannelIds,
		);
		let totalPointsFromToRemoveUnsavedChannels = 0;
		toRemoveUnsavedChannels.forEach((channel) => {
			totalPointsFromToRemoveUnsavedChannels += channel.points;
		});

		const getSavedUnsavedBBSpeedPointDiff = () => {
			const unsavedSelectedFrihetBBSpeedProduct =
				rootGetters["mittTelenor/fixed/frihetSubscription/unsavedSelectedFrihetBBSpeedProduct"];
			const savedSelectedFrihetBBSpeedProduct =
				rootGetters["mittTelenor/fixed/frihetSubscription/savedSelectedFrihetBBSpeedProduct"];
			const pointsFromUnsavedSelectedFrihetBBSpeed = unsavedSelectedFrihetBBSpeedProduct?.prices?.POINTS?.amount || 0;
			const pointsFromSavedSelectedFrihetBBSpeed = savedSelectedFrihetBBSpeedProduct?.prices?.POINTS?.amount || 0;
			let savedUnsavedBBSpeedDifference = 0;
			if (unsavedSelectedFrihetBBSpeedProduct) {
				savedUnsavedBBSpeedDifference = -pointsFromSavedSelectedFrihetBBSpeed;
				savedUnsavedBBSpeedDifference += pointsFromUnsavedSelectedFrihetBBSpeed;
			}
			return savedUnsavedBBSpeedDifference;
		};
		const getSavedUnsavedTvPointDiff = () => {
			const unsavedSelectedFrihetTvProduct =
				rootGetters["mittTelenor/fixed/frihetSubscription/unsavedSelectedFrihetTvProduct"];
			const savedSelectedFrihetTvProduct =
				rootGetters["mittTelenor/fixed/frihetSubscription/savedSelectedFrihetTvProduct"];
			const unsavedSelectedTvProductPoints = unsavedSelectedFrihetTvProduct?.prices?.POINTS?.amount || 0;
			const savedSelectedTvProductPoints = savedSelectedFrihetTvProduct?.prices?.POINTS?.amount || 0;
			let savedUnsavedPointsDiff = 0;
			if (unsavedSelectedFrihetTvProduct) {
				savedUnsavedPointsDiff = -savedSelectedTvProductPoints;
				savedUnsavedPointsDiff += unsavedSelectedTvProductPoints;
			}
			const disableTvProduct = rootGetters["mittTelenor/fixed/frihetSubscription/disableTvProduct"];
			if (unsavedSelectedFrihetTvProduct && unsavedSelectedFrihetTvProduct?.productId === disableTvProduct?.productId) {
				savedUnsavedPointsDiff -= getters.subscribedContentTotalPointValue;
			}
			return savedUnsavedPointsDiff;
		};
		const getCostDefaultSetup = getters.isAddDefaultSetup ? getters.costDefaultSetup : 0;

		return (
			totalPointsFromToAddUnsavedChannels -
			totalPointsFromToRemoveUnsavedChannels +
			getSavedUnsavedBBSpeedPointDiff() +
			getSavedUnsavedTvPointDiff() +
			getCostDefaultSetup
		);
	},
	subscribedContentTotalPointValue: (state, getters, rootState, rootGetters) => {
		let total = 0;
		const subscribedChannels = rootGetters["mittTelenor/fixed/tvContent/getChannelsByIds"](
			getters.subscriptionTvContent?.subscribedContent,
		);
		subscribedChannels?.forEach((channel) => {
			total += channel?.points || 0;
		});
		return total;
	},
	unsavedExtraPointPackages: (state, getters) => {
		return Math.ceil(getters.pointsOverIncludedAndBought / getters.pointsInPackage) || 0;
	},
	includedPoints: (state, getters) => {
		return getters.subscriptionTvContent?.pointsSummary?.included || 0;
	},
	freePoints: (state, getters) => {
		return getters.subscriptionTvContent?.pointsSummary?.free || 0;
	},
	boughtPoints: (state, getters) => {
		return getters.subscriptionTvContent?.pointsSummary?.bought || 0;
	},
	totalUsedPoints: (state, getters) => {
		return getters.subscriptionTvContent?.pointsSummary?.used + getters.unsavedUsedPoints || 0;
	},
	extraPointsUsed: (state, getters) => {
		if (getters.totalUsedPoints > getters.includedPoints) {
			return getters.totalUsedPoints - getters.includedPoints;
		}
		return 0;
	},
	pointsInPackage: (state, getters) => {
		return getters.subscriptionTvContent?.extraPointPackage?.points || 0;
	},
	costDefaultSetup: (state, getters) => {
		return getters.subscriptionTvContent?.costDefaultSetup || 0;
	},
	pointsOverIncludedAndBought: (state, getters) => {
		const pointsOverIncludedAndBought = getters.extraPointsUsed - getters.boughtPoints + getters.freePoints;
		return pointsOverIncludedAndBought;
	},
	pointsUnderIncludedAndBought: (state, getters) => {
		const allPoints = getters.includedPoints + getters.boughtPoints + getters.freePoints;
		const pointsUnderIncludedAndBought = allPoints - getters.totalUsedPoints;
		return pointsUnderIncludedAndBought > 0 ? pointsUnderIncludedAndBought : 0;
	},
	totalPointsFromPackages: (state, getters) => {
		const pointsFromFreePointPackages =
			getters.subscriptionTvContent?.numberOfFreePointPackages * getters.pointsInPackage;
		const pointsFromSubscribedPointPackages =
			getters.subscriptionTvContent?.numberOfSubscribedPointPackages * getters.pointsInPackage;
		let pointsFromUnsavedExtraPointPackages = 0;
		// do not include negative packages
		if (getters.unsavedExtraPointPackages > 0) {
			pointsFromUnsavedExtraPointPackages = getters.unsavedExtraPointPackages * getters.pointsInPackage;
		}

		return pointsFromFreePointPackages + pointsFromSubscribedPointPackages + pointsFromUnsavedExtraPointPackages || 0;
	},
	includedPointsUsed: (state, getters) => {
		if (getters.totalUsedPoints > getters.includedPoints) {
			return getters.includedPoints;
		}
		return getters.totalUsedPoints;
	},
	pointPackageCostNok: (state, getters) => {
		if (getters.subscriptionTvContent?.extraPointPackage?.price?.currency === "NOK") {
			return getters.subscriptionTvContent?.extraPointPackage?.price?.amount;
		}
		return 0;
	},
	isChannelInSubscription: (state, getters) => (channelId) => {
		return !!getters.subscriptionTvContent?.subscribedContent?.filter((id) => id === channelId).length;
	},
	isChannelSelected: (state, getters) => (channelId) => {
		return (
			getters.toAddUnsavedChannelIds.includes(channelId) ||
			(getters.isChannelInSubscription(channelId) && !getters.toRemoveUnsavedChannelIds.includes(channelId))
		);
	},
	getSelectedMutualExclusiveChannelByMutualExclusiveGroup:
		(state, getters, rootState, rootGetters) => (mutuallyExclusiveGroup) => {
			const mutualExclusiveChannels =
				rootGetters["mittTelenor/fixed/tvContent/getChannelsByMutualExclusiveGroup"](mutuallyExclusiveGroup);
			return mutualExclusiveChannels.find((channel) => getters.isChannelSelected(channel.id));
		},
	isChannelChangeNotSaved: (state, getters) => (channelId) => {
		return getters.toAddUnsavedChannelIds.includes(channelId) || getters.toRemoveUnsavedChannelIds.includes(channelId);
	},
	lockedDaysRemaining: (state, getters) => (channelId) => {
		return getters.subscriptionTvContent?.lockedContent?.find((channel) => channel.id === channelId)?.remainingDays;
	},
	mutualExclusiveLockedDaysRemaining: (state, getters) => (mutuallyExclusiveGroup) => {
		const selectedMutualChannel =
			getters.getSelectedMutualExclusiveChannelByMutualExclusiveGroup(mutuallyExclusiveGroup);
		return getters.lockedDaysRemaining(selectedMutualChannel?.id);
	},
	processSelectedContentValidationResult: (state, getters) => (validationResult) => {
		const packagesToRemove = getters.unsavedExtraPointPackages < 0 ? Math.abs(getters.unsavedExtraPointPackages) : 0;
		const packagesToAdd = getters.unsavedExtraPointPackages > 0 ? Math.abs(getters.unsavedExtraPointPackages) : 0;

		const invalidPackageAmount =
			!validationResult.successful ||
			packagesToRemove !== validationResult.numberOfPointPackagesToRemove ||
			packagesToAdd !== validationResult.numberOfPointPackagesToAdd;

		const error = { message: "", code: "" };
		if (validationResult?.errors?.length) {
			validationResult.errors.forEach((err) => {
				error.message += err.message + "</br>";
				error.code += err.code + "</br>";
			});
		}
		if (invalidPackageAmount) {
			error.message += "Poengpakke antall i klient stemmer ikke med respons fra validerings API.";
		}
		if (invalidPackageAmount || validationResult?.errors?.length) {
			return { error };
		}
	},
	getSubscribedThirdpartyChannels: (state, getters, rootState, rootGetters) => {
		return rootGetters["mittTelenor/fixed/tvContent/getChannelsByIds"](
			getters.subscriptionTvContent?.subscribedContent,
		)?.filter((channel) => channel.thirdPartyInfo);
	},
};
