<script setup lang="ts">
import type TnwebPage from "~/server/usecases/content/definitions/TnwebPage";
import { useStore } from "vuex";
import { usePageStore } from "~/pinia/page";

import layouts from "~/gizmo-layouts.ts";
import telenorid from "~/telenorid/telenorid";

import type { NuxtError } from "#app";

const props = defineProps({
	error: {
		type: Object as () => NuxtError,
		default: () => {},
	},
});

console.error(props.error);

const store = useStore();
const pageStore = usePageStore();

const errorPage = useNuxtApp().$config.public.globalAssets.error[props.error.statusCode];

const { data: loadedPage } = await useFetch<TnwebPage>(`/api/content/page/${encodeURIComponent(errorPage)}`, {
	deep: false,
});

try {
	if (loadedPage.value) await pageStore.setPage(loadedPage.value);
} catch (err) {
	console.error(err);
}

const page = pageStore.page;

const layoutRef = page?.template?.ref || "default";

const layout = layouts[layoutRef];

useHead({
	title: page.metadata?.title,
	meta: [
		{ name: "description", content: page?.metadata?.description },
		{ name: "keywords", content: page?.metadata?.keywords },
	],
});

if (!store.state.attemptedLogin) {
	onMounted(async () => {
		const user = await telenorid.getOrLoginUser();
		if (user) await store.dispatch("setUser", user);

		store.commit("setAttemptedLogin", true);
	});
}

const HERO_COMPONENTS = [
	"hero-banner--normal",
	"hero-banner--video",
	"gateway-links-bar",
	"hero-banner--video-twe",
	"vev-component-no-spacing",
];

let pageColor = "white";

if (page?.metadata?.dark || layoutRef === "tweDefault") pageColor = "dark";

const sections = page?.layouts;
const segmentedSections = sections.filter(useComponentSegmentation);

const breadcrumbsBelowHero = computed(() => {
	return HERO_COMPONENTS.includes(sections?.[0]?.columns?.[0]?.[0]?.template?.name);
});

useChat();
</script>

<template>
	<NuxtLayout>
		<div class="wrapper full-page-container layoutOuterContainer">
			<component
				:is="layout"
				v-if="loadedPage"
			>
				<div :class="{ 'color-theme--dark': sections?.[0]?.background === 'dark' }">
					<div
						class="content-container"
						v-if="!breadcrumbsBelowHero"
					>
						<LazyBreadcrumbs
							:dark="sections?.[0]?.background === 'dark' || pageColor === 'dark'"
							:metadata="page?.metadata"
							:page="page"
							:layouts="sections"
						/>
					</div>
				</div>
				<TSection
					:layout="section"
					:index="index"
					:page-color="pageColor"
					v-for="(section, index) in segmentedSections"
					:key="`section-${index}`"
				/>
			</component>
			<div
				class="padding-vertical-2xl"
				v-else
			>
				<div class="page-container">
					<h1>{{ props.error.message }}</h1>
					<pre v-html="props.error.stack"></pre>
				</div>
			</div>
		</div>
		<div
			id="tn-chat"
			class=""
			data-auto-open="false"
			data-auto-start="false"
		></div>
	</NuxtLayout>
</template>

<style scoped lang="scss">
.layoutOuterContainer {
	min-height: 100vh;
}
</style>
