import ecommerceIntegration from "~/integrations/ecommerce-v2-integration";
import contentServiceIntegration from "~/integrations/content-service-integration";

import { useStore } from "vuex";

async function handleComponentPage(component) {
	return {
		component,
		template: {
			ref: "$tnweb/pages/component",
		},
	};
}

const publicConfig = {
	isPreview: false,
};

export const usePageStore = defineStore("page", () => {
	const page = ref({
		layouts: [],
		metadata: {},
	});
	const vuexStore = useStore();

	const setPage = async (newPage) => {
		let resolvedProduct;
		vuexStore.commit("ecommerce/resetProduct");

		if (newPage.template && newPage.template.type === "component") {
			newPage = await handleComponentPage(newPage);
			if (newPage.metadata?.product) {
				resolvedProduct = await ecommerceIntegration.getProductById(
					newPage.metadata.product,
					vuexStore.getters["ecommerce/segment"],
				);
				vuexStore.commit("ecommerce/setProduct", resolvedProduct);
			} else if (newPage.eCommerceProduct) {
				vuexStore.commit("ecommerce/setProduct", newPage.eCommerceProduct);
				delete newPage.eCommerceProduct;
			}

			page.value = newPage;
		} else {
			if (publicConfig.isPreview) newPage = await loadABTestPage(newPage);

			if (newPage.metadata?.product) {
				resolvedProduct = await ecommerceIntegration.getProductById(
					newPage.metadata.product,
					vuexStore.getters["ecommerce/segment"],
				);
				vuexStore.commit("ecommerce/setProduct", resolvedProduct);
			} else if (newPage.eCommerceProduct) {
				vuexStore.commit("ecommerce/setProduct", newPage.eCommerceProduct);
			}

			if (newPage && newPage.layouts && newPage.layouts.length > 0) {
				if (!newPage.template) {
					console.error("No page template set", newPage);
					newPage.template = {
						ref: "$tnweb/pages/default",
					};
					page.value = newPage;
				} else {
					page.value = newPage;
				}
			}
		}
	};

	const loadPage = async (url: string) => {
		const newPage = await contentServiceIntegration.getByUrl(url);
		await setPage(newPage);
	};

	const loadABTestPage = (page) => {
		if (
			publicConfig.isPreview &&
			vuexStore.state.testIndex &&
			page &&
			page.abTestPages &&
			page.abTestPages.length > 1
		) {
			try {
				const testIndex = parseInt(vuexStore.state.testIndex);
				const clonedPage = JSON.parse(JSON.stringify(page.abTestPages[testIndex]));
				clonedPage.abTestPages = page.abTestPages;
				clonedPage.abTestSetup = page.abTestSetup;
				return clonedPage;
			} catch (e) {
				console.error("Could not setup AB Test page: ", e.message);
			}
		}
		return page;
	};

	return {
		page,
		setPage,
		loadPage,
		loadABTestPage,
	};
});