const isBetween = (date: Date, startDate: Date, endDate: Date) => date >= startDate && date <= endDate;

const isBefore = (date: Date, compareDate: Date) => date < compareDate;

const isAfter = (date: Date, compareDate: Date) => date > compareDate;

const isToday = (date: Date) => {
	const today = new Date();
	return (
		date.getDate() == today.getDate() &&
		date.getMonth() == today.getMonth() &&
		date.getFullYear() == today.getFullYear()
	);
};

const isTomorrow = (date: Date) => {
	const today = new Date();
	return (
		date.getDate() == today.getDate() + 1 &&
		date.getMonth() == today.getMonth() &&
		date.getFullYear() == today.getFullYear()
	);
};

const getTime = (date: Date) => {
	const hours = date.getHours();
	const minutes = date.getMinutes();
	return `${hours}:${minutes}`;
};

export default { isBetween, isBefore, isAfter, isToday, isTomorrow, getTime };
