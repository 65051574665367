// import ecommerceIntegration from "~/integrations/ecommerce-v2-integration";
import customerIntegration from "~/integrations/customers";

// const publicConfig = {
// 	isPreview: false,
// };

// import contentServiceIntegration from "~/integrations/content-service-integration";
import telenorid from "~/telenorid/telenorid.js";

export const state = () => {
	return {
		form: {
			requiredFields: [],
			formSubmitted: false,
			totalFileSize: 0,
			values: {},
			submitting: false,
		},
		showLogoutNotice: false,
		isPreview: false,
		showSpacing: false,
		siteSegment: "",
		selectTriggerElement: false,
		testIndex: undefined,
		testType: 0,
		host: "",
		errorPages: {},
		customer: null,
		attemptedLogin: false,
		execSegmentation: true,
		termsAccepted: false,
		bankIdUser: null,
		isTest: false,
		faroUrl: "",
		isMobile: false,
		globals: {
			privat: {
				header: null,
				footer: null,
			},
			bedrift: {
				header: null,
				footer: null,
			},
		},
		userAgent: "",
		isAutoScrolling: false,
		activeIndex: undefined,
	};
};
export const mutations = {
	setAutoScrolling(state, isAutoScrolling) {
		state.isAutoScrolling = isAutoScrolling;
	},
	setActiveIndex(state, activeIndex) {
		state.activeIndex = activeIndex;
	},
	setFaroUrl(state, faroUrl) {
		state.faroUrl = faroUrl;
	},
	setShowLogoutNotice(state, showLogoutNotice) {
		state.showLogoutNotice = showLogoutNotice;
	},
	setGlobals(state, globals) {
		state.globals[globals.segment] = globals.globals;
	},
	setAttemptedLogin(state, attemptedLogin) {
		state.attemptedLogin = attemptedLogin;
	},
	setTermsAccepted(state, termsAccepted) {
		state.termsAccepted = termsAccepted;
	},
	setSegments(state, segments) {
		// TODO: Move to actions in gizmo.js
		state.customer = { ...state.customer, segments };
	},
	setTargetServerState(state, serverState) {
		// TODO: Remove (move to module)
		state.targetServerState = serverState;
	},
	setMobile(state, isMobile) {
		state.isMobile = isMobile;
	},
	setLocals(state, locals) {
		state.selectTriggerElement = locals.selectTriggerElement;
		state.testIndex = locals.testIndex;
		state.testType = locals.testType;
		state.host = locals.host;
	},
	setHost(state, host) {
		state.host = host;
	},
	setIsTest(state, isTest) {
		state.isTest = isTest;
	},
	setPreview(state) {
		state.isPreview = true;
	},
	setSiteSegment(state, segment) {
		// This is not a getter because in search, you can toggle the segment without manipulating the URL
		state.siteSegment = segment;
	},
	setSelectTriggerElement(state, selectTriggerElement) {
		// TODO: Move to Gizmo module
		state.selectTriggerElement = selectTriggerElement;
	},
	setTestIndex(state, testIndex) {
		// TODO: Move to Gizmo module
		state.testIndex = testIndex;
	},
	setTestType(state, testType) {
		// TODO: Move to Gizmo module
		state.testType = testType;
	},
	setFormStatus(state) {
		state.form["formSubmitted"] = true;
	},
	setFormSubmitting(state, submitting) {
		state.form.submitting = submitting;
	},
	addTotalFileSize(state, totalFileSize) {
		state.form["totalFileSize"] += totalFileSize;
	},
	subtractTotalFileSize(state, totalFileSize) {
		state.form["totalFileSize"] -= totalFileSize;
	},
	resetTotalFileSize(state) {
		state.form["totalFileSize"] = 0;
	},
	addRequiredFormField(state, field) {
		if (!state.form["requiredFields"].some((item) => item.uid === field.uid)) {
			state.form["requiredFields"].push(field);
			state.form["requiredFields"].sort((a, b) => (a.uid > b.uid ? 1 : -1));
		}
	},
	removeRequiredFormField(state, uid) {
		state.form["requiredFields"] = state.form["requiredFields"].filter((item) => item.uid !== uid);
	},
	resetRequiredFormFields(state) {
		state.form["requiredFields"] = [];
	},
	setFormValue(state, field) {
		state.form.values[field.name] = field.value;
	},
	resetFormValues(state) {
		state.form.values = [];
	},
	setCustomer(state, customer) {
		state.customer = customer;
	},
	setBankIdUser(state, bankIdUser) {
		state.bankIdUser = bankIdUser;
	},
	updateCustomer(state, customer) {
		state.customer = { ...state.customer, ...customer };
	},
	setUserAgent(state, userAgent) {
		state.userAgent = userAgent;
	},
	setExecSegmentation(state, execSegmentation) {
		state.execSegmentation = execSegmentation;
	},
	setDynamicAnalyticsData(state, data) {
		state.dynamicAnalyticsData = data;
	},
	toggleShowSpacing(state) {
		state.showSpacing = !state.showSpacing;
	},
};
export const actions = {
	setAutoScrolling({ commit }, isAutoScrolling) {
		commit("setAutoScrolling", isAutoScrolling);
	},
	setActiveIndex({ commit }, activeIndex) {
		commit("setActiveIndex", activeIndex);
	},
	async setUser({ commit }, user) {
		if (user?.profile) {
			let customerObject = {
				access_token: user.access_token,
				id_token: user.id_token,
				kurtId: user.profile.kurtid,
				firstName: user.profile.given_name,
				lastName: user.profile.family_name,
				birthDate: user.profile.birthdate,
				isOrganization: false,
				address: {},
				postalAddress: {},
				segments: (user.segments && Array.from(new Set([...user.segments, "LoggedIn"]))) || [],
				amr: user.profile.amr,
				acr: user.profile.acr,
			};

			commit("setCustomer", customerObject);

			return customerObject;
		} else if (user?.firstName) {
			commit("setCustomer", user);
			return user;
		} else {
			throw new Error("Could not set user, profile is missing");
		}
	},
	clearCustomer({ commit }) {
		commit("setCustomer", null);
		commit("contactDetails/setContactDetails", null);

		commit("setShowLogoutNotice", true);
		setTimeout(() => commit("setShowLogoutNotice", false), 5500);
	},
	async setCustomerClientSide({ commit }) {
		return customerIntegration
			.getCustomerData()
			.then(({ customerIds, ...customer }) => {
				commit("updateCustomer", { ...customer, kurtId: customerIds.kurtId });
				return { error: false };
			})
			.catch((e) => {
				console.error(e);
				return { error: true };
			});
	},
	async logout() {
		return telenorid.signoutRedirect();
	},
};
export const getters = {
	getAutoScrolling: (state) => state.isAutoScrolling,
	getActiveIndex: (state) => state.activeIndex,
	getErrorPage: (state) => (statusCode) => state.errorPages[statusCode],
	getGlobalSegment: (state) => state.siteSegment,
	getRequiredFormFields: (state) => state.form.requiredFields,
	getFormValues: (state) => state.form.values,
	segments: (state) => state.customer?.segments || [],
	segmentationDisabled: (state) => {
		return [state.gizmo?.showAllSegmentedContent, state.isPreview, state.gizmo?.dragType === "component"].some(
			(e) => e,
		);
	},
	isMobile: (state) => {
		return state.isMobile;
	},
	kurtId: (state) => {
		if (state.customer) return state.customer.kurtId;
		return "";
	},
	getAuthenticatedCustomer: (state) => state.customer,
};
