<template>
	<div
		class="section-layout"
		:class="[layoutClasses, { compact }]"
	>
		<div
			v-for="(column, colIndex) in segmentedLayout"
			:key="`col-${colIndex}`"
		>
			<template
				v-for="(component, compIndex) in column"
				:key="`dropzone-${colIndex}-${compIndex}`"
			>
				<ClientOnly v-if="editable && dropZoneVisible">
					<div>
						<LazyComponentDropzone
							:dark="layout.background === 'dark'"
							:layout-idx="index"
							:col-idx="colIndex"
							:comp-idx="compIndex"
						/>
					</div>
				</ClientOnly>
				<div class="component">
					<slot
						:colIndex="colIndex"
						:compIndex="compIndex || 0"
						:component="component"
						:isLast="compIndex === column.length - 1"
					/>
				</div>
			</template>
			<ClientOnly
				v-if="isPreview && ((editable && column.length === 0) || (editable && dropZoneVisible && column.length > 0))"
			>
				<!-- Inline editing: Empty column placeholders are always visible -->
				<div>
					<LazyComponentDropzone
						:always-visible="column.length === 0"
						:dark="layout.background === 'dark'"
						:layout-idx="index"
						:col-idx="colIndex"
						:comp-idx="0"
						v-if="editable && column.length === 0"
					/>
					<LazyComponentDropzone
						v-if="editable && dropZoneVisible && column.length > 0"
						:dark="layout.background === 'dark'"
						:layout-idx="index"
						:col-idx="colIndex"
						:comp-idx="column.length"
					/>
				</div>
			</ClientOnly>
		</div>
		<ClientOnly v-if="isPreview">
			<LazyGizmoDeleteBtn
				:data-path="dataPath"
				class="delete-layout"
				v-if="showGizmoDeleteButton"
			/>
		</ClientOnly>
	</div>
</template>
<script>
import SectionLayout from "~/mixins/SectionLayout";
import { mapState as mapVuexState } from "vuex";
import { mapState as mapPiniaState } from "pinia"; // temporary "as" alias to avoid conflict with vuex
import { usePageStore } from "~/pinia/page";
export default defineNuxtComponent({
	name: "TLayout",

	props: {
		layout: { type: Object },
		segmentedLayout: { type: Array },
		index: { type: Number },
		dataPath: { type: String, default: "" },
	},

	mixins: [SectionLayout],

	computed: {
		...mapVuexState(["isPreview"]),
		...mapPiniaState(usePageStore, ["page"]),
		layoutClasses() {
			if (this.page.metadata?.layoutConfig) {
				return this.$_SectionLayout_getLayoutClasses(this.page.metadata.layoutConfig.numberOfColumns);
			}
			return this.$_SectionLayout_getLayoutClasses(this.layout.columns.length);
		},
		compact() {
			return this.$_SectionLayout_isCompact(this.page.template?.id);
		},
		showGizmoDeleteButton() {
			return this.isPreview && this.layout.columns && this.editable && this.layout.columns.flat().length < 1;
		},
		editable() {
			return this.$config.public.isPreview; // && !this.$store.state?.selectTriggerElement;
		},
		dropZoneVisible() {
			return this.$store.state.gizmo?.dragType === "component";
		},
	},
});
</script>
<style lang="scss" scoped>
.section-layout {
	position: relative;
	margin: 0 auto; // can this be removed?
	width: 100%;
	height: 100%;
	display: grid;
	transition: height 2s ease;
}

.component {
	+ .component {
		margin-top: $spacing-2xl;
	}

	@include breakpoint(mobile) {
		max-width: calc(100vw - 32px);
	}
}

.full-width .component {
	@include breakpoint(mobile) {
		max-width: 100vw;
	}
}

.compact .component {
	+ .component {
		margin-top: $spacing-m;
	}
}
</style>
