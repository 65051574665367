import { mapState } from "pinia";
import { usePageStore } from "~/pinia/page";
export default {
	mounted() {
		if (this.isForm) {
			this.$store.commit("resetRequiredFormFields");
			this.$store.commit("resetTotalFileSize");
		}
	},
	computed: {
		...mapState(usePageStore, ["page"]),
		metadata() {
			return this.layout?.metadata;
		},
		isForm() {
			return this.metadata && (this.metadata.formEmail || this.metadata.apiEndpoint);
		},
		formWrapperAttributes() {
			if (this.isForm) {
				return {
					method: "POST",
					enctype: "multipart/form-data",
					action: `/api/form/${this.page.id}`,
					name: "formwrapper",
				};
			} else {
				return {};
			}
		},
	},
	methods: {
		trackStart() {
			try {
				window.utag &&
					window.utag.link({
						formStart: "1",
					});
			} catch (err) {
				console.error("Error tracking formStart", err.message);
			}
		},
		async handleSubmit(e) {
			e.preventDefault();
			let invalidField = this.invalidField();
			if (invalidField) {
				const invalidElement = document.querySelector(`.component[id='${invalidField}']`);
				if (invalidElement) {
					invalidElement.focus();
					invalidElement.scrollIntoView({ behavior: "smooth", block: "center" });
				}
			} else {
				const data = new FormData(e.target);

				try {
					this.$store.commit("setFormSubmitting", true);
					const newPageState = await $fetch(`/api/form/${this.page.id}`, {
						method: "POST",
						body: data,
					});

					this.internalLayout = newPageState;
					this.$store.commit("setFormStatus");

					//trackEnd
					try {
						window.utag &&
							window.utag.link({
								formEnd: "1",
							});
					} catch (err) {
						console.error("Error tracking formEnd", err.message);
					}
				} catch (err) {
					console.error("Error submitting form", err.message);
				} finally {
					this.$store.commit("setFormSubmitting", false);

					await this.$forceUpdate();

					const errors = document.querySelectorAll(".error");

					if (errors.item(0)) errors.item(0).scrollIntoView();

					const serverError = document.getElementById("form-submission-error");

					if (serverError) serverError.scrollIntoView();
				}
			}
		},
		invalidField() {
			let requiredFields = this.$store.getters.getRequiredFormFields;
			requiredFields.forEach((field) => {
				if (typeof field.validate === "function") field.validate();
			});

			return requiredFields.length > 0 ? requiredFields[0].elementId : null;
		},
	},
};
