import apigeeIntegration from "./self-service-adapter";

const api = apigeeIntegration("/consumer-fault-search/v1");

const startFlow = async (subId, flowId) => api.get(`subscription/${subId}/flow/${flowId}/start`);

const nextStep = async (subId, flowId, stepId, sessionId, body, conclusion) =>
	api.put(`subscription/${subId}/flow/${flowId}/step`, {
		stepId: stepId,
		session: sessionId,
		inputParameters: {
			...body,
		},
		conclusion,
	});

export default {
	startFlow,
	nextStep,
};
