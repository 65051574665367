import { _fetchData } from "~/helpers/store";
import digitalSelvhjelp from "~/integrations/digital-selvhjelp";

export const state = () => ({
	promise: null,
	data: null,
	error: null,
	session: null,
	conclusion: null,
	updateView: false,
});
export const actions = {
	async startFlow(context, { subId }) {
		let flowId = subId.includes("C_TV") ? "ssnl_twe_ftv" : "ssnl_bb_ftv";

		return _fetchData(() => digitalSelvhjelp.startFlow(subId, flowId), {
			context,
			force: true,
			name: "Digital Selvhjelp",
		});
	},
	async nextStep(context, { subId, stepId, body, conclusion }) {
		let flowId = subId.includes("C_TV") ? "ssnl_twe_ftv" : "ssnl_bb_ftv";

		return _fetchData(() => digitalSelvhjelp.nextStep(subId, flowId, stepId, context.state.session, body, conclusion), {
			context,
			force: true,
			name: "Digital Selvhjelp",
		});
	},
};
export const mutations = {
	setData(state, data) {
		state.data = data;
		if (data?.session) {
			state.session = data.session;
		}
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	setConclusion(state, conclusion) {
		state.conclusion = conclusion;
	},
	setUpdateView(state, updateView) {
		state.updateView = updateView;
	},
};
export const getters = {
	loading: (state) => state.promise !== null && !state.error,
	isError: (state) => state.error,
	currentStep: (state) => state.data,
	updateView: (state) => state.updateView,
	statusLine: (state) => state.data?.statusLine,
};
