export default function () {
	onNuxtReady(() => {
		const { $store } = useNuxtApp();
		const page = $store.state?.page;

		const disableChat =
			page?.metadata?.disableChat ||
			useNuxtApp().$config.public.disableChat ||
			window.location.hostname === "localhost";

		if (!disableChat) {
			const script = document.createElement("script");
			script.src = "https://chat.telenor.no/client.js";
			document.getElementsByTagName("body")[0].appendChild(script);
		}
	});
}
