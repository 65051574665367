<template>
	<div
		class="component-wrapper"
		:class="[{ editable, last }]"
		:data-path="dataPath"
	>
		<ClientOnly>
			<LazyGizmoEditBtn
				v-if="editable"
				v-bind="{ layoutIdx, colIdx, compIdx }"
				:data-path="dataPath"
			/>
		</ClientOnly>
		<Component
			:is="resolvedComponent"
			:component="componentWithoutTemplate || component"
			:content="content"
			:metadata="metadata"
			:template="template"
			:id="component.id"
			:dark="dark"
			:theme="theme"
			:hasBackground="hasBackground"
			class="component"
			:class="{ 'has-bg': hasBackground }"
			:editable="inlineEditable"
		/>
		<ClientOnly>
			<LazyComponentIdLabel
				:id="component.id"
				v-if="showComponentIdLabel"
			/>
			<LazySegmentationLabel
				color="green"
				:segments="mustSegments"
				v-if="mustSegments && mustSegments.length > 0"
			/>
			<LazySegmentationLabel
				color="red"
				:segments="mustNotSegments"
				v-if="mustNotSegments && mustNotSegments.length > 0"
			/>
			<LazyGizmoDeleteBtn
				v-if="editable"
				:data-path="dataPath"
			/>
			<LazyTnNotification
				v-if="componentWithoutTemplate"
				type="warning"
				title="GIZMO ERROR"
			>
				<LazyTnParagraph class="margin-bottom-m">
					A component is not working as intended and needs your attention!
				</LazyTnParagraph>
				<LazyAnchorLink
					:href="`#${component.id}`"
					text="Go to component"
					:chevron-animated="true"
					:chevron-right="true"
				/>
			</LazyTnNotification>
		</ClientOnly>
	</div>
</template>
<script>
import SegmentLabels from "~/mixins/SegmentLabels";
import componentList from "~/gizmo-components.ts";

export default defineNuxtComponent({
	name: "TComponent",

	props: {
		component: {
			type: Object,
			required: true,
		},
		dark: {
			type: Boolean,
		},
		theme: {
			type: String,
		},
		layoutIdx: {},
		colIdx: {},
		compIdx: {},
		last: { type: Boolean },
		hasBackground: { type: Boolean },
		toyt: { type: Boolean },
	},

	mixins: [SegmentLabels],
	methods: {
		handleContentChanged(event) {
			console.log("handleContentChanged");
			if (this.$config.public.isPreview) {
				if (event.oldValue === event.value) return;
				console.log("posting message", event);
				if (this.component.link) {
					// Unlink reused component before doing changes
					parent.postMessage(
						{
							action: "change",
							payload: {
								dataPath: this.dataPath + "/link",
								value: false,
							},
						},
						"*",
					);
				}
				parent.postMessage(
					{
						action: "change",
						payload: {
							dataPath: this.dataPath + "/" + ((event.prop && event.prop.replace(".", "/")) || ""),
							value: event.value,
							oldValue: !event.prop && event.oldValue,
							unknownProp: !event.prop,
						},
					},
					"*",
				);
			}
		},
	},

	mounted() {
		this.$el.addEventListener("contentChanged", (event) => {
			event.stopPropagation();
			this.handleContentChanged(event.detail);
		});
	},

	computed: {
		componentName() {
			if (this.component.template?.ref) {
				let componentName = this.component.template.ref.split("/").pop();
				const dontUse = this.component.template.name && this.component.template.name.indexOf("dont-use") > -1;
				if (componentName === "productCard") componentName = "listCards"; // Should not be renamed in Gizmo

				// exceptions that should be renamed in gizmo component templates
				if (componentName === "serviceEntrances") componentName = "ListIcons";
				if (componentName === "image") componentName = "OldImage";
				if (componentName === "heroBanner" && dontUse) componentName = "HeroBannerDontUse";
				if (componentName === "heroBannerNormal") componentName = "HeroBanner";
				if (componentName === "stickyButtonv2") componentName = "StickyButton";
				if (componentName === "email-breach-check") componentName = "EmailBreachCheck";
				if (componentName === "table" && this.component.template.name.indexOf("generator") > -1)
					componentName = "TableGenerator";
				if (componentName === "remote_help") componentName = "RemoteHelp";
				if (componentName === "listTweChannels") componentName = "ListTvChannels";
				if (componentName === "entrance") componentName = "ListEntrances";

				if (componentName === "input_textfield") componentName = "InputFieldText";
				if (componentName === "input_textarea") componentName = "InputTextarea";
				if (componentName === "input_textfield_date") componentName = "InputFieldDate";
				if (componentName === "input_textfield_email") componentName = "InputFieldEmail";
				if (componentName === "input_textfield_fullname") componentName = "InputFieldFullName";
				if (componentName === "input_textfield_orgnr") componentName = "InputTextfieldOrgnr";
				if (componentName === "input_time") componentName = "InputFieldTime";
				if (componentName === "input_checkboxGroup") componentName = "InputCheckboxGroup";
				if (componentName === "input_radioButtonGroup") componentName = "InputRadioButtonGroup";
				if (componentName === "input_captcha") componentName = "InputCaptcha";
				if (componentName === "input_numberfield_phone") componentName = "InputFieldPhoneNumber";
				if (componentName === "select") componentName = "InputDropdown";
				if (componentName === "ctiDateBooker") componentName = "CTIDateBooker";

				if (componentName === "segmentSelector") componentName = "ListCards";

				componentName = componentName.charAt(0).toUpperCase() + componentName.slice(1);
				return componentName;
			} else if (this.$config.public.isPreview) {
				return this.componentWithoutTemplate.template.ref;
			} else {
				return "";
			}
		},
		resolvedComponent() {
			const key = Object.keys(componentList).find(
				(key) => key.toLowerCase() === this.componentName.toLowerCase().replace(/-/g, ""),
			);

			if (key) return componentList[key];
		},
		dataPath() {
			if (this.layoutIdx !== undefined) {
				let dataPath = "/layouts/" + this.layoutIdx + "/columns/" + this.colIdx + "/" + this.compIdx;
				if (this.$config.public.isPreview && this.$store.state.testIndex) {
					dataPath = "/abTestPages/" + this.$store.state.testIndex + dataPath;
				}
				return dataPath;
			} else {
				return "";
			}
		},
		editable() {
			return this.$config.public.isPreview && !this.$store.state.selectTriggerElement;
		},
		inlineEditable() {
			return this.editable && !this.componentWithoutTemplate;
		},
		showComponentIdLabel() {
			return (
				this.$config.public.isPreview &&
				this.component.segmentation &&
				Object.keys(this.component.segmentation).length > 0
			);
		},
		componentWithoutTemplate() {
			if (this.$config.public.isPreview && !this.component.template?.ref) {
				return {
					...this.component,
					template: {
						ref: "AlertMessage",
					},
					content: {
						messageHeading: "GIZMO ERROR",
						messageText: `Missing component template: ${this.component.schema?.title}`,
					},
					editable: false,
				};
			} else {
				return null;
			}
		},
		content() {
			return this.component.content ?? {};
		},
		metadata() {
			return this.component.metadata ?? {};
		},
		template() {
			return this.component.template ?? {};
		},
	},
});
</script>

<style lang="scss" scoped>
.component-wrapper {
	// padding: 0.05px; // Hack to disable margin collapsing... Forgive me

	&.editable {
		position: relative;
		background: transparent;
		transition: background-color 0.1s;

		:deep(.editable-property) {
			border-bottom: 1px solid transparent;
		}

		&:hover {
			:deep(.editable-property) {
				background-color: rgb(0 0 0 / 5%);
				border-bottom-color: rgb(0 0 0 / 10%);
				cursor: text;
			}

			:deep(.edit-btn),
			:deep(.delete-btn) {
				opacity: 1;
			}

			:deep(.segmentation-label) {
				opacity: 0;
			}

			:deep(.component-id-label) {
				opacity: 0;
			}
		}
	}

	@media screen and (width >= calc($size-screen-phone + 1px)) {
		&.last {
			margin-bottom: 0 !important;
		}
	}
}
</style>
