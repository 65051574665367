<template>
	<Component
		:is="isForm ? 'form' : 'section'"
		v-if="segmentedLayout.length > 0 || $config.public.isPreview"
		:id="'l-' + index"
		:class="[...sectionWidth, sectionClasses, { editable, dark }]"
		:color="color"
		class="container-fluids"
		v-bind="formWrapperAttributes"
		@submit="handleSubmit"
		@update:modelValue.once="trackStart"
	>
		<ClientOnly>
			<LazyGizmoLayoutProperties
				v-if="editable"
				:idx="index"
				:layout="layout"
			/>
			<LazySegmentationLabel
				v-if="mustSegments.length"
				:dark="dark"
				:segments="mustSegments"
				color="green"
			/>
			<LazySegmentationLabel
				v-if="mustNotSegments.length"
				:dark="dark"
				:segments="mustNotSegments"
				color="red"
			/>
			<input
				type="hidden"
				name="token"
				:value="customer.access_token"
				v-if="isFormWithEndUserAuth && customer"
			/>
		</ClientOnly>
		<!-- TODO some issues with hero banners -->
		<div
			:data-path="dataPath"
			class="content-container"
		>
			<TLayout
				:data-path="dataPath"
				:dataPath="dataPath"
				:index="index"
				:layout="layout"
				:segmentedLayout="segmentedLayout"
			>
				<template #default="slotProps">
					<TComponent
						:col-idx="slotProps.colIndex"
						:comp-idx="slotProps.compIndex"
						:component="slotProps.component"
						:dark="dark"
						:hasBackground="hasBackground"
						:last="slotProps.isLast"
						:layout-idx="index"
						:pagemeta="page.metadata"
						:theme="theme"
						:toyt="layout.toyt"
					/>
				</template>
			</TLayout>
		</div>
	</Component>
</template>

<script>
import BackgroundColor from "../../../../mixins/BackgroundColor";
import InlineEditableLayoutMixin from "~/components/platform/inline-editing/InlineEditableLayoutMixin.js";
import FormMixin from "~/mixins/FormMixin.js";
import SupportsSegmentation from "~/mixins/SupportsSegmentation";
import SegmentLabels from "~/mixins/SegmentLabels";
import SectionLayout from "~/mixins/SectionLayout";
import { mapGetters, mapState as mapVuexState } from "vuex";
import { mapState as mapPiniaState } from "pinia";
import { usePageStore } from "~/pinia/page";

const identity = (x) => x;

const COMPONENT_WIDTH_BLACKLIST = ["gatewayLinksBar", "heroBannerVideo", "heroBannerNormal", "heroBannerCarousel"];
const COMPONENT_ARTICLE_WIDTH_WHITELIST = ["banner5050", "gridProductsFilter", "listCards", "gizmoSubscriptionCardsV2"];

const PAGE_TEMPLATE_WIDTH_OVERRIDES = {
	"template-telenor.no-page-telenor-no-metadata-twe-box-web-app-page": "full-width",
	"telenor.no-page-telenor-no-metadata-twe-box-web-app-page": "full-width",
};

// Locals
const NO_SPACING_COMPONENTS = [
	"hero-banner--normal",
	"hero-banner--video",
	"hero-banner-carousel",
	"gateway-links-bar",
	"hero-banner--video-twe",
	"vev-component-no-spacing",
];

const SPACING = {
	big: (placement) => `section-spacing-${placement}-big`,
	default: (placement) => `section-spacing-${placement}`,
};

export default defineNuxtComponent({
	name: "TSection",
	props: ["layout", "index", "isTweWebApp", "pageColor"],
	mixins: [BackgroundColor, InlineEditableLayoutMixin, SupportsSegmentation, SegmentLabels, FormMixin, SectionLayout],

	data() {
		return {
			is7030: this.layout.width === "70%/30%",
			submitted: false,
			internalLayout: this.layout || [],
		};
	},

	computed: {
		...mapGetters(["segments"]),
		...mapVuexState(["customer"]),
		...mapPiniaState(usePageStore, ["page"]),
		spacing() {
			const getBackgroundColor = (index) => {
				if (index < 0) return this.pageColor;
				const background = this.page.layouts[index]?.background;
				return [!background, background === "transparent"].some((e) => e) ? this.pageColor : background;
			};

			const verticalSpacing = { top: "", bottom: "" };
			const isSingleColumnSection = this.internalLayout.columns.length === 1;

			const differentColorAbove = getBackgroundColor(this.index - 1) !== getBackgroundColor(this.index);
			const differentColorBelow = getBackgroundColor(this.index + 1) !== getBackgroundColor(this.index);

			// Normal Spacing Rules
			if (this.index === 0) {
				verticalSpacing.top = SPACING.default("top");
				verticalSpacing.bottom = differentColorBelow ? SPACING.big("bottom") : SPACING.default("bottom");
			} else if (this.index === this.page.layouts.length - 1) {
				verticalSpacing.top = differentColorAbove ? SPACING.big("top") : SPACING.default("top");

				verticalSpacing.bottom = SPACING.default("bottom");
			} else {
				verticalSpacing.top = differentColorAbove ? SPACING.big("top") : SPACING.default("top");

				verticalSpacing.bottom = differentColorBelow ? SPACING.big("bottom") : SPACING.default("bottom");
			}

			// Special Cases Where Top/Bottom Spacing is removed completely
			if (isSingleColumnSection) {
				const firstComponentInSection = this.internalLayout.columns?.[0][0]?.template?.name;
				const lastComponentInSection =
					this.internalLayout.columns?.[0][this.internalLayout.columns[0].length - 1]?.template?.name;

				if (NO_SPACING_COMPONENTS.includes(firstComponentInSection)) {
					verticalSpacing.top = "";
				}
				if (NO_SPACING_COMPONENTS.includes(lastComponentInSection)) {
					verticalSpacing.bottom = "";
				}
			}
			return verticalSpacing;
		},
		compact() {
			return this.$_SectionLayout_isCompact(this.page.template?.id);
		},
		hasBackground() {
			return this.color && this.color !== "white" && this.color !== "transparent";
		},
		sectionClasses() {
			const classes = [];

			// For now, since page color is white by default, transparent means white.
			if (this.color && this.color !== "white" && this.color !== "transparent") {
				classes.push("has-bg");
				if (this.pageColor !== "dark") classes.push(this.backgroundColor);
			}

			if (this.pageColor === "dark") return [...classes, this.spacingClasses, this.backgroundColor];
			return [...classes, this.spacingClasses];
		},
		// part of sectionspacing
		spacingClasses() {
			return [
				this.spacing.top,
				this.spacing.bottom,
				...(this.compact ? ["compact"] : []),
				...(this.$store.state.showSpacing ? ["showSpacing"] : []),
			];
		},
		isFormWithEndUserAuth() {
			if (!this.isForm) return false;
			if (this.metadata.apiEndpoint?.startsWith("http")) {
				const url = new URL(this.metadata.apiEndpoint);
				return url.hostname.endsWith("api.telenor.no");
			}
		},
		sectionWidth() {
			const classes = [];
			const hasComponentInArticleWhitelist = this.internalLayout?.columns?.some((column) => {
				return column.some((component) => {
					return COMPONENT_ARTICLE_WIDTH_WHITELIST.includes(component.template?.ref);
				});
			});
			if (this.page.template?.id in PAGE_TEMPLATE_WIDTH_OVERRIDES) {
				classes.push(PAGE_TEMPLATE_WIDTH_OVERRIDES[this.page.template?.id]);
			} else if (hasComponentInArticleWhitelist) {
				const width = this.internalLayout.width;
				if (this.is7030) classes.push("layout-width-100");
				const widthClass = width && `layout-width-${width.replace("%", "")}`;
				classes.push(widthClass);
				return classes;
			} else if (this.page.metadata?.sectionConfig?.width) {
				classes.push(this.page.metadata.sectionConfig.width);
			} else if (this.internalLayout.columns.length === 1) {
				const templateRef = this.internalLayout.columns[0][0]?.template?.ref;
				const firstComponentInSection = templateRef?.split("/").pop();
				const firstComponentNameInSection = this.internalLayout.columns[0][0]?.template?.name;

				if (COMPONENT_WIDTH_BLACKLIST.includes(firstComponentInSection)) {
					classes.push("full-width");
				} else if (["vev-component-no-spacing"].includes(firstComponentNameInSection)) {
					classes.push("full-width");
				}
			}
			const width = this.internalLayout.width;
			if (this.is7030) classes.push("layout-width-100");
			const widthClass = width && `layout-width-${width.replace("%", "")}`;
			classes.push(widthClass);
			return classes;
		},
		color() {
			if (this.isTweWebApp) {
				return "twe-web-app";
			}
			return this.internalLayout.background;
		},
		theme() {
			return this.color && this.color === "white"
				? "white"
				: this.color && this.color === "twe-web-app"
					? "twe-web-app"
					: this.color && this.color === "dark"
						? "dark"
						: "color";
		},
		showAllSegmentedContent() {
			return (
				this.$config.public.isPreview &&
				[this.$store.state.gizmo?.showAllSegmentedContent, this.$store.state.gizmo?.dragType === "component"].some(
					identity,
				)
			);
		},
		segmentedLayout() {
			if (this.$config.public.isPreview) return this.internalLayout.columns;
			return this.internalLayout.columns
				.map((column) => column.filter(identity).filter(this.filterComponent))
				.filter((column) => column.length > 0);
		},
		dark() {
			return (
				this.internalLayout.background &&
				(this.internalLayout.background === "dark" || this.internalLayout.background === "black")
			);
		},
		editable() {
			return this.$config.public.isPreview && !this.$store.state.selectTriggerElement;
		},
	},
});
</script>

<!-- Add this to get a scope on .content-container that's used on the InlineEditableLayoutMixin.js -->
<style lang="scss" scoped>
.section-spacing-top {
	padding-top: $spacing-4xl;

	&.showSpacing::before {
		content: "4xl";
		height: $spacing-4xl;
	}

	&.compact {
		padding-top: $spacing-2xl;
	}

	&.compact.showSpacing::before {
		content: "2xl" !important;
		height: $spacing-2xl !important;
	}

	&-big {
		padding-top: $spacing-5xl;

		&.showSpacing::before {
			content: "5xl";
			height: $spacing-5xl;
		}

		&.compact {
			padding-top: $spacing-3xl;
		}

		&.compact.showSpacing::before {
			content: "3xl" !important;
			height: $spacing-3xl !important;
		}
	}
}

.section-spacing-bottom {
	padding-bottom: $spacing-4xl;

	&.showSpacing::after {
		content: "4xl";
		height: $spacing-4xl;
	}

	&.compact {
		padding-bottom: $spacing-2xl;
	}

	&.compact.showSpacing::after {
		content: "2xl" !important;
		height: $spacing-2xl !important;
	}

	&-big {
		padding-bottom: $spacing-5xl;

		&.showSpacing::after {
			content: "5xl";
			height: $spacing-5xl;
		}

		&.compact {
			padding-bottom: $spacing-3xl;
		}

		&.compact.showSpacing::after {
			content: "3xl" !important;
			height: $spacing-3xl !important;
		}
	}
}

// Just for visualizing the spacing
.showSpacing {
	position: relative;

	&::before,
	&::after {
		display: flex;
		font-size: 28px;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		background: repeating-linear-gradient(
			-45deg,
			rgba($color-primary-mid, 0.1),
			rgba($color-primary-mid, 0.1) 20px,
			rgba($color-primary-mid, 0.2) 20px,
			rgba($color-primary-mid, 0.2) 22px
		);
		z-index: 10;
		color: $color-primary-mid;
		box-sizing: border-box;
		border: 1px solid $color-primary-mid;
		width: 100%;
	}

	&::after {
		bottom: 0;
	}

	&::before {
		top: 0;
	}
}

@include breakpoint(mobile) {
	.section-spacing-top {
		padding-top: $spacing-3xl;

		&.showSpacing::before {
			content: "3xl";
			height: $spacing-3xl;
		}

		&.compact {
			padding-top: $spacing-xl;
		}

		&.compact.showSpacing::before {
			content: "xl" !important;
			height: $spacing-xl !important;
		}

		&-big {
			padding-top: $spacing-4xl;

			&.showSpacing::before {
				content: "4xl" !important;
				height: $spacing-4xl !important;
			}

			&.compact {
				padding-top: $spacing-2xl;
			}

			&.showSpacing::before {
				content: "2xl";
				height: $spacing-2xl;
			}
		}
	}

	.section-spacing-bottom {
		padding-bottom: $spacing-3xl;

		&.showSpacing::after {
			content: "3xl";
			height: $spacing-3xl;
		}

		&.compact {
			padding-bottom: $spacing-xl;
		}

		&.compact.showSpacing::after {
			content: "xl" !important;
			height: $spacing-xl !important;
		}

		&-big {
			padding-bottom: $spacing-4xl;

			&.showSpacing::after {
				content: "4xl";
				height: $spacing-4xl;
			}

			&.compact {
				padding-bottom: $spacing-2xl;
			}

			&.compact.showSpacing::after {
				content: "2xl" !important;
				height: $spacing-2xl !important;
			}
		}
	}
}
</style>

<!-- This can't be scoped since Adobe Target breaks/removes the data-v id -->
<style lang="scss">
.spinner-wrapper {
	display: block;
	width: 100%;
	text-align: center;

	:deep(.spinner) {
		width: 20px;
	}
}

.editable {
	position: relative;

	&:hover {
		.gizmo-layout-properties {
			opacity: 1;
		}

		.delete-btn.delete-layout {
			opacity: 1;
		}

		.content-container {
			.section-layout {
				&::after,
				&::before {
					content: "";
					width: 4px;
					border-radius: 4px;
					background: rgba($color-primary-mid, 0.5);
					position: absolute;
					height: 100%;
					top: 0;
					transition: background 0.4s ease-in-out;
					z-index: 30;
				}

				&::after {
					right: -30px;
				}

				&::before {
					left: -30px;
				}
			}
		}

		.dark {
			.content-container {
				.section-layout {
					&::before,
					&::after {
						background: #38486b;
					}
				}
			}
		}
	}

	&.resizing {
		.content-container {
			transition: width 0.2s ease;
		}

		.section-layout {
			.layout-width-percentage {
				display: block;
				z-index: 10;
			}
		}
	}

	// check the ecommerce product page
	.column-wrapper {
		border-left: 3px double transparent;
		border-right: 3px double transparent;
		margin-left: auto;
		margin-right: auto;
		transition:
			border-left-color 0.2s ease-in-out,
			border-right-color 0.2s ease-in-out;
		touch-action: none;
		height: 100%;

		&.no-select {
			user-select: none;
		}
	}
}
</style>
