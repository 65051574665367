import systemUserIntegration from "~/integrations/self-service-system-adapter";

const api = systemUserIntegration("/ecommerce-shipping/v1/provider");

const porterBuddy = {
	availability: async (
		address: {
			streetName: string;
			streetNumber: string;
			postalCode: string;
			postalPlace: string;
		},
		options: {
			sameDayDelivery: boolean;
		},
	) => {
		try {
			const delivery = {
				address,
				options,
			};

			return await api.post("porterbuddy/availability", delivery);
		} catch (e) {
			console.error(e);
		}
	},
};

export default {
	porterBuddy,
};
