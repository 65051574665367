import { _fetchData } from "~/helpers/store";
import contentServiceIntegration from "~/integrations/content-service-integration";
import flowUtils from "@/store/flow/flowUtils";
import { usePageStore } from "~/pinia/page";

const getNodeById = (graph, id) => graph?.find((node) => node.id === id);
const getPageUrlByNodeId = (graph, id) => getNodeById(graph, id)?.page?.url;
const getFromState = (state, key) => {
	let levels = key.split("/");
	try {
		return levels.reduce((total, current) => total[current], state);
	} catch {
		// Probably hit a nullpointer
	}
};

export const state = () => {
	return {
		data: [],
		promise: null,
		error: null,
		previousProgress: 0,
		flowName: null,
	};
};
export const actions = {
	// fetching the graph data
	async fetchData(context, { force, pageId }) {
		return _fetchData(() => contentServiceIntegration.getFlow(pageId), {
			context,
			force: force,
			name: "flow-graph: " + pageId,
		});
	},
};
export const mutations = {
	setPreviousProgress(state, progress) {
		state.previousProgress = progress;
	},
	setIsTestCondition(state, isTestCondition) {
		state.isTestCondition = isTestCondition;
	},
	setData(state, data) {
		state.data = data?.metadata?.graph?.graphData2 || [];

		state.flowName = data?.metadata?.flowName ?? null;
	},
	setPromise(state, promise) {
		state.promise = promise;
	},
	setError(state, error) {
		state.error = error;
	},
	setFlowName(state, flowName) {
		state.flowName = flowName;
	},
};
export const getters = {
	flowGraph: (state) => state.data || [],

	loadingFlow: (state) => !!state.promise,

	error: (state) => state.error,

	prevAction: (_, getters) => getters?.newCurrentPathProgress[getters?.newCurrentPathProgress?.length - 2] || "",

	isLastStep: (_, getters) => getters?.currentNode?.id === getters?.endNode?.id,

	isFirstStep: (_, getters) => getters?.currentNode?.id === getters?.startNode?.id,

	previousProgress: (state) => state.previousProgress,

	nextAction: (_, getters) =>
		getters?.adjacencyList?.[getters?.currentNode?.id]?.edges
			?.filter(getters?.stateQualifiesForEdge)
			?.map((next) => getPageUrlByNodeId(getters?.flowGraph, next?.target))?.[0] || "",

	currentStepOptional: (_, getters) =>
		getters?.adjacencyList?.[getters?.currentNode?.id]?.edges
			?.filter(getters?.stateQualifiesForEdge)
			?.map((x) => console.log(x) || x)
			?.every((edge) => edge?.config?.conditions?.length === 0 || !edge?.config?.conditions),

	progress: (_, getters) => getters.newCurrentPathProgress?.length / getters.newCurrentPath?.length,

	stateQualifiesForEdge: (state, getters, rootState, rootGetters) => (edge) => {
		if (!edge.config?.conditions) return true;
		return edge.config.conditions.every((condition) => {
			condition = flowUtils.convertNuxt2StorePathToNuxt3(condition);

			let negate = condition[0] === "!";
			if (negate) condition = condition.replace("!", "");

			let fromState = getFromState(rootState, condition);
			let fromGetters = rootGetters[condition];

			let returnValue;
			if (fromState) returnValue = fromState;
			if (fromGetters) returnValue = fromGetters;

			if (negate) returnValue = !returnValue;

			return returnValue;
		});
	},
	startNode: (_, getters) =>
		getters?.allNodes?.filter((node) => !getters?.allEdges?.map((edge) => edge.target).includes(node.id))?.[0],

	endNode: (_, getters) =>
		getters?.allNodes?.filter((node) => !getters?.allEdges?.map((edge) => edge.source).includes(node.id))?.[0],

	allNodes: (_, getters) => getters.flowGraph?.filter((node) => !!node.page),

	allEdges: (_, getters) => getters.flowGraph?.filter((edge) => !edge.page), // should have a type/label NODE|EDGE

	currentNode(_, getters) {
		const pageStore = usePageStore();
		return getters.allNodes?.find((node) => node.page.id === pageStore.page.id);
	},

	adjacencyList(_, getters) {
		let adjacency = {};
		for (let node of getters.allNodes) {
			let outgoingEdges = getters.allEdges
				?.filter((edge) => edge.source === node.id)
				?.sort((aWeight, bWeight) => bWeight?.config?.conditions?.length - aWeight?.config?.conditions?.length);
			adjacency = {
				...adjacency,
				[node.id]: {
					edges: outgoingEdges,
				},
			};
		}
		return adjacency;
	},

	newCurrentPathProgress(state, getters) {
		let stack = [];
		let valid = [];
		let closed = [];
		let next;
		closed.push({ path: [getters?.startNode?.id], weight: 0 });
		if (getters.startNode?.id === getters?.currentNode?.id) {
			valid.push({ edge: {}, path: [getters?.startNode?.id], weight: 0 });
		} else {
			getters.adjacencyList?.[getters?.startNode?.id]?.edges?.forEach((edge) => {
				stack.push({
					edge: edge,
					path: [getters.startNode?.id, edge?.target],
					weight: 0 + (edge?.config?.conditions?.length || 0),
				});
			});
			while (stack.length > 0) {
				[next, ...stack] = stack;
				let ok = getters.stateQualifiesForEdge(next?.edge);
				if (ok) {
					closed.push(next);
					if (next.edge.target === getters?.currentNode?.id) {
						valid.push(next);
					} else {
						getters.adjacencyList?.[next?.edge?.target]?.edges?.forEach((edge) => {
							stack.push({
								edge: edge,
								path: [...next.path, edge?.target],
								weight: (edge?.config?.conditions?.length || 0) + next?.weight,
							});
						});
					}
				}
			}
		}
		if (valid.length > 0) {
			if (valid.length === 1) {
				return valid.flatMap((found) => found.path?.map((nodeid) => getPageUrlByNodeId(getters.flowGraph, nodeid)));
			} else {
				return valid
					.reduce((acc, curr) => {
						acc = (acc?.[0]?.weight || 0) < curr.weight ? [curr] : acc;
						return acc;
					}, [])
					?.flatMap((found) => found?.path?.map((nodeid) => getPageUrlByNodeId(getters.flowGraph, nodeid)));
			}
		} else {
			if (closed.length === 1) {
				return closed?.flatMap((mostValid) =>
					mostValid?.path?.map((nodeid) => getPageUrlByNodeId(getters.flowGraph, nodeid)),
				);
			} else {
				return closed
					.reduce((acc, curr) => {
						acc = (acc?.[0]?.weight || 0) < curr.weight ? [curr] : acc;
						return acc;
					}, [])
					?.flatMap((mostValid) => mostValid?.path?.map((nodeid) => getPageUrlByNodeId(getters.flowGraph, nodeid)));
			}
		}
	},
	newCurrentPath(_, getters) {
		let lastValidPath = getters.newCurrentPathProgress[getters?.newCurrentPathProgress?.length - 1];
		let lastValidNodeId = getters.allNodes.find((node) => node?.page?.url === lastValidPath)?.id;
		let stack = [];
		let valid = [];
		let closed = [];
		let next;
		// return [];
		if (lastValidNodeId === getters?.endNode?.id) {
			return getters?.newCurrentPathProgress;
		} else {
			getters.adjacencyList?.[lastValidNodeId]?.edges?.forEach((edge) => {
				stack.push({
					edge: edge,
					path: [edge?.target],
					weight: edge?.config?.conditions?.length || 0,
				});
			});
			while (stack.length > 0) {
				[next, ...stack] = stack;
				closed.push(next);
				if (next?.edge?.target === getters?.endNode?.id) {
					valid.push(next);
				} else {
					getters.adjacencyList?.[next?.edge?.target]?.edges?.forEach((edge) => {
						stack.push({
							edge: edge,
							path: [...next.path, edge?.target],
							weight: (edge?.config?.conditions?.length || 0) + next?.weight,
						});
					});
				}
			}
			if (valid?.length === 1) {
				let continuation = valid?.flatMap((found) =>
					found?.path?.map((nodeid) => getPageUrlByNodeId(getters.flowGraph, nodeid)),
				);
				return [...getters.newCurrentPathProgress, ...continuation];
			} else {
				let continuation = valid
					?.reduce((acc, curr) => {
						acc = (acc?.[0]?.weight || 0) < curr?.weight ? [curr] : acc;
						return acc;
					}, [])
					?.flatMap((found) => found?.path?.map((nodeid) => getPageUrlByNodeId(getters.flowGraph, nodeid)));
				return [...getters.newCurrentPathProgress, ...continuation];
			}
		}
	},
};
