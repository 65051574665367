import abTestTrack from "@telenornorgeinternal/tn-gizmo-abtest-plugin";
import storeModule from "./inline-editing/store-module.js";
import { usePageStore } from "~/pinia/page.ts";

const containsAbTest = (dataPath) => {
	let found = false;
	if (Array.isArray(dataPath)) {
		dataPath.forEach((d) => {
			if (d.startsWith("/abTestPages")) found = true;
		});
	} else if (dataPath.startsWith("/abTestPages")) found = true;
	return found;
};

export default defineNuxtPlugin((nuxtApp) => {
	const store = nuxtApp.store;
	const pageStore = usePageStore();

	if (nuxtApp.$config.public.isPreview) {
		store.registerModule("gizmo", storeModule);

		if (document.domain.indexOf("localhost") < 0) document.domain = "telenor.no";
		else document.domain = "localhost";

		if (store.state.selectTriggerElement) {
			abTestTrack.initGobalSelectTriggerElement(nuxtApp.$config.domain, store.state.testType);

			nuxtApp.app.router.beforeEach(() => {
				return false; // prevent navigation
			});
		}

		window.onmessage = (message) => {
			if (message.data) {
				console.log(message.data);
				let { type, change } = message.data;
				if (type && type === "change") {
					store.commit("handleChange", change);
					if (containsAbTest(change.dataPath)) {
						// Reload page store object with current AB test changes from delta
						pageStore.loadABTestPage(pageStore.page);
					}
				} else if (type && type === "delete") {
					store.commit("handleDelete", change);
					if (containsAbTest(change.dataPath)) {
						// Reload page store object with current AB test changes from delta
						pageStore.loadABTestPage(pageStore.page);
					}
				} else if (type && type === "draggingLayout") {
					store.commit("gizmo/setDragType", "layout");
				} else if (type && type === "draggingComponent") {
					store.commit("gizmo/setDragType", "component");
				} else if (type && type === "dragEnd") {
					store.commit("gizmo/setDragType", "");
				} else if (type && type === "goto") {
					let dataPath = `/layouts/${change.layout}/columns/${change.column}/${change.component}`;
					if (change.abtest !== undefined) dataPath = `/abTestPages/${change.abtest}${dataPath}`;
					let el = document.querySelector(`[data-path="${dataPath}"]`);
					el.scrollIntoView();
				} else if (type && type === "reload") {
					window.location.reload();
				} else if (type && type === "segments") {
					store.dispatch("gizmo/setSegments", change);
				} else if (type && type === "labels") {
					store.commit("gizmo/setLabels", change);
				} else if (type && type === "showAllSegmentedContent") {
					store.commit("gizmo/setShowAllSegmentedContent", change);
				} else if (type && type === "simulateLoggedIn") {
					store.commit("gizmo/setSimulateLoggedIn", change);
				} else if (type && type === "device") {
					store.dispatch("gizmo/spoofUserAgent", change);
				}
			}
		};
	}
});
